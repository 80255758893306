<template>
  <div v-loading="loading">
    <ui-card
      :heading="`${$t('actions.edit')} ${$tc('models.course')}`"
      class="mb-8"
    >
      <course-form
        v-if="course"
        :course="course"
        :invalid="invalid"
        :invalidNo="invalidNo"
        :invalidEn="invalidEn"
        :invalidBuilding="invalidBuilding"
        :invalidCourseType="invalidCourseType"
        @submit="handleSubmit"
        @cancel="handleCancel"
      ></course-form>
    </ui-card>

    <ui-card :heading="`${$t('actions.actions')} ${$tc('models.course')}`">
      <ui-link type="danger" @click="handleDelete">{{
        `${$t('actions.delete')} ${$tc('models.course')}`
      }}</ui-link>
      <ui-link type="primary" class="ml-4" @click="handleCopy">{{
        `${$t('actions.copy')} ${$tc('models.course')}`
      }}</ui-link>
    </ui-card>

    <ui-modal
      v-if="!loading && deleteEffect"
      :show="showDeleteModal"
      :text="$t('actions.confirm_delete', { model: $tc('models.course') })"
      :info="
        $t('labels.course.delete_effect', {
          userCount: deleteEffect.userCount,
          lessonCount: deleteEffect.lessonCount
        })
      "
      :confirmText="$t('actions.delete')"
      @confirm="deleteCourse"
      @cancel="showDeleteModal = false"
    />

    <copy-modal
      v-if="!loading"
      :show="showCopy"
      :course="course"
      @cancel="showCopy = false"
    />
  </div>
</template>

<script>
import UiCard from '@/components/ui/UiCard';
import UiLink from '@/components/ui/UiLink';
import UiModal from '@/components/ui/UiModal';
import CourseForm from '../components/CourseForm';
import CopyModal from '../components/CopyModal';
import { getCourse, updateCourse, deleteCourse, getDeleteEffect } from '../api';

export default {
  data() {
    return {
      course: null,
      deleteEffect: null,
      loading: false,
      showDeleteModal: false,
      showCopy: false
    };
  },

  components: {
    UiCard,
    UiLink,
    UiModal,
    CopyModal,
    CourseForm
  },

  methods: {
    async getCourse() {
      this.loading = true;
      try {
        const course = await getCourse(this.$route.params.id);
        this.course = course;
        const effect = await getDeleteEffect(course.id);
        this.deleteEffect = effect;
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    async handleSubmit() {
      this.loading = true;
      if (this.course.courseType.name === 'student') {
        this.course.hasExam = true;
      }
      try {
        const result = await updateCourse(this.course);
        this.$router.push({ name: 'courses-index' });
        this.$message({
          message: this.$t('feedback.update_success'),
          type: 'sucess'
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        let addon = '';
        if (
          this.course.courseType &&
          this.course.courseType.name == 'student'
        ) {
          addon = '\nDet kan kun være et studentkurs pr bygg.';
        }
        this.$message({
          message: `${this.$t('errors.general')}${addon}`,
          type: 'error'
        });
      }
    },

    handleDelete() {
      this.showDeleteModal = true;
    },

    handleCopy() {
      this.showCopy = true;
    },

    async deleteCourse() {
      this.loading = true;
      try {
        const result = await deleteCourse(this.course.id);
        this.showDeleteModal = false;
        this.$router.back();
        this.loading = false;
      } catch (error) {
        this.$message({ message: this.$t('errors.general'), type: 'error' });
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.back();
    }
  },

  computed: {
    invalidNoTitle() {
      return this.course.title[0].value !== '' ? 0 : 1;
    },

    invalidNoDescr() {
      return this.course.description[0].value !== '' &&
        this.course.description[0].value !== '<p></p>'
        ? 0
        : 1;
    },

    invalidNo() {
      return this.invalidNoTitle + this.invalidNoDescr;
    },

    invalidEnTitle() {
      return this.course.title[1].value !== '' ? 0 : 1;
    },

    invalidEnDescr() {
      return this.course.description[1].value !== '' &&
        this.course.description[1].value !== '<p></p>'
        ? 0
        : 1;
    },

    invalidEn() {
      return this.invalidEnTitle + this.invalidEnDescr;
    },

    invalidBuilding() {
      return this.course.building && this.course.building.id ? 0 : 1;
    },

    invalidCourseType() {
      return this.course.courseType && this.course.courseType.id ? 0 : 1;
    },

    invalid() {
      return (
        this.invalidNo +
        this.invalidEn +
        this.invalidBuilding +
        this.invalidCourseType
      );
    }
  },

  mounted() {
    this.$store.commit('changeBreadcrumbs', [
      {name: "models.a_course", link: "/app/courses/"},
      {name: "actions.edit", link: ""}
    ]);
  },

  created() {
    this.getCourse();
  }
};
</script>


